
import React, { useState} from 'react';
import {Dialog,DialogTitle, DialogContent, DialogActions,Button } from '@material-ui/core';
const PublishFileStatus = ({updateFormStatusFn,handleClose,modal, recordsPermission})=> {
    return (
        <Dialog open={modal} onClose={handleClose}>
            <DialogTitle>How do you want to publish ?</DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '440px' }}>
                        <p>
                            Please Select "Publish & Apply to Records" if you want to publish the
                            form and store the records in Records Dataroom. Select "Publish Only"
                            if you want to only publish the form
                        </p> 
                </div>
            </DialogContent>
                <DialogActions>
                    { recordsPermission?
                        <Button variant="contained" color="primary" onClick={() => { handleClose(); updateFormStatusFn({ status: 7, update_on_tech_specs: true }) }}>
                        Publish & Apply to Records
                    </Button>
                    : null}
                    <Button variant="contained" color="primary" onClick={() => { handleClose(); updateFormStatusFn({ status: 7 }) }}>
                        Publish only
                    </Button>
                    <Button onClick={handleClose} color="secondary" variant="contained">
                        Cancel
                    </Button>
                </DialogActions> 
        </Dialog>
    )
}
export default PublishFileStatus