import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Paper, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { ProjectList, ProjectTabs, ProjectCRU, ProjectStatusPopUp } from '../components';
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader ,DeletePopUp} from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { sLesseeListAc } from '../../../shared/actionCreators';
import { getProjectsApi, getProjectDetailsApi, getProjectFormsApi, exportProjectsApi,deleteProjectList } from '../apiServices';
import { removeEmptyKey, convertFilterObject, onCheckPermission,getLocalStorageInfo } from '../../../utils_v2';
import { projectHd, projectFilterOps, createProject } from '../';
import FilterComponent from '../../../shared_elements/filter_component'
import { NotListedLocationOutlined } from '@material-ui/icons';
import { trackActivity } from '../../../utils/mixpanel';
import config from '../../../config'
import { castleAirInstance } from '../../../shared_elements/components';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import LogoutInfo from '../../../shared/components/LogoutInfo';
const queryString = require('query-string');
class ProjectListing extends Component {
  constructor(props,location) {
    super(props)
    this.state = {
      projectId:null,
      projectCrud:null,
      projectModal:false,
      skeletonLoader: true,
      pageLoader: false,
      formSubmitLoader: false,
      tabIndex: queryString.parse(props.location.search).project_status
        ? queryString.parse(props.location.search).project_status
        : "all",
      filter: {},
      applyingFilter: {},
      projectStatus:{modal:false, data:null, slug:''},
      projectsInfo: {
        pagination: {},
        list: [],
        projects_count: [],
        permission:{}
      },
      sort: "",
      sort_by: "",
      allForms: [],
      deleteProjectModal:false,
      deleteItem:null
    }
    this.getProjectsApi = getProjectsApi.bind(this)
    this.deleteProjectList = deleteProjectList.bind(this)
    this.exportProjectsApi = exportProjectsApi.bind(this)
    this.getProjectDetailsApi = getProjectDetailsApi.bind(this)
    this.getProjectFormsApi = getProjectFormsApi.bind(this)
  }
  componentDidMount() {
    let query = { per_page: 10 }
    if (queryString.parse(this.props.location.search)) {
      query = {
        ...query,
        ...queryString.parse(this.props.location.search)
      }
    }
    this.getProjectsApi(this.props, query, 'skeletonLoader');
    this.getProjectFormsApi(this.props, {both:true});
    this.props.getLesseeList();
    trackActivity('Project Management', {
      event_type: 'Page Visited',
      page_title: 'Project Listing',
    })
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, tabIndex, projectsInfo } = this.state
    this.setState({
      sort: sortField,
      sort_by:
        sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc",
    })
    if (sortField === sort) {
      if (sort_by === "asc") {
        this.getProjectsApi(
          this.props,
          {
            ...filter,
            sort: sortField,
            sort_by: "desc",
            per_page: projectsInfo.pagination.per_page,
            project_status: tabIndex,
          },
          "pageLoader"
        )
      } else {
        this.getProjectsApi(
          this.props,
          {
            ...filter,
            sort: sortField,
            sort_by: "asc",
            per_page: projectsInfo.pagination.per_page,
            project_status: tabIndex,
          },
          "pageLoader"
        )
      }
    } else {
      this.getProjectsApi(
        this.props,
        {
          ...filter,
          sort: sortField,
          sort_by: "asc",
          per_page: projectsInfo.pagination.per_page,
          project_status: tabIndex,
        },
        "pageLoader"
      )
    }
  }

  handleTabChange = (event, newValue) => {
    // this.props.match.history.push({pathname: '/projects',search: '?project_status='+newValue});
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      query: {
        ...location.query,
        project_status: newValue,
      },
    }
    browserHistory.push(location)
    this.getProjectsApi(this.props, location?.query, "pageLoader")
    this.setState((prevState) => ({
      ...prevState,
      tabIndex: newValue,
    }))
    localStorage.setItem('lisitng_url', JSON.stringify(location))
  }
  getResponseBack = (res, opsType) => {
    const { filter, sort, projectsInfo, tabIndex } = this.state;
    if(opsType === 'edit'){
      this.setState(prevState => ({
        ...prevState,
        projectModal:false,
        projectCrud:null,
        projectsInfo: {
          ...prevState.projectsInfo,
          list: prevState.projectsInfo.list.map(item => item.id === res.id ? res : item)
        }
      }));
      this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
    }else{
      this.setState({projectModal:false, projectCrud:null, projectId:null});
      this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
    }
  }
  setFilterUrl =(query)=>{
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      query: {
        ...query,
        project_status:queryString.parse(this.props.location.search).project_status
        ? queryString.parse(this.props.location.search).project_status
        : "all"
      },
    }
    this.getProjectsApi(this.props, {...location?.query, page:1, per_page:this.state.projectsInfo.pagination.per_page}, 'pageLoader')
    browserHistory.push(location)
    localStorage.setItem('lisitng_url', JSON.stringify(location))
  }
  render(){
    const { projectsInfo, projectModal, projectCrud, sort, sort_by, filter, applyingFilter, tabIndex, modal, skeletonLoader, pageLoader, formSubmitLoader, allForms,deleteProjectModal,deleteItem, projectStatus } = this.state
    const { lessee } = this.props;
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )
    let filterOptions = {}
    filterOptions = {
      ...projectFilterOps,
      lessee: {
        ...projectFilterOps.lessee,
        options: lessee,
      },
      forms: {
        ...projectFilterOps.forms,
        options: allForms.map((item) => ({
          id: item.id,
          name: `${item.name}-${item.title}`
        })),
      },
    }
    if (castleAirInstance) {
      filterOptions = {
        ...filterOptions,
        'project_remarks': {
          'inputType': 'text',
          'placeholder': 'Description',
          'title': 'Description'
        },
      }
    }
    return(
      <section className='projects-management-section'>
        <DeploymentMessage />
        <LogoutInfo />
        <div className="projects-list-sec">
          {skeletonLoader ? (
            <STabsLoader count={7} />
          ) : (
            <ProjectTabs
              handleTabChange={this.handleTabChange}
              tabIndex={tabIndex}
              count={projectsInfo.projects_count}
            />
          )}
          {skeletonLoader ? (
            <STableLoader count={9} />
          ) : (
            <Fragment>
            <Paper square className="filter-cta">
                <Grid alignItems='center' container spacing={1}>
                  <Grid item xs={9} >
                  <FilterComponent
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => {this.setFilterUrl(applyFilter);}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ul className='list-inline project-list-cta'>
                      { onCheckPermission('project_management', 'projects', 'C') ?
                        <li className='list-inline-item'>
                          <Button onClick={() => this.setState({projectCrud:createProject, projectModal:true})} color="primary" variant='contained' size="small">Add Project</Button>
                        </li>:null
                      }
                      { onCheckPermission('project_management', 'projects', 'EXP') ?
                        <li className='list-inline-item'>
                          <ExportManu
                             disabled={projectsInfo.list.length === 0 ? true:false}
                             title="Export" files={[{title:'Excel', extension: 'xls', key:''}]}
                             exportReportFn={(file) => this.exportProjectsApi(this.props, {download:file.extension, ...removeEmptyKey(filter), project_status: tabIndex}, file)}
                          />
                        </li>:null
                      }
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
              <TableListComp
                style={{maxHeight:(window.innerHeight - 230) + 'px'}}
                heads={smbcInstance || castleAirInstance ? projectHd : projectHd.filter(column => column.id !== 'project_remarks')}
                sort={sort}
                sort_by={sort_by}
                data={projectsInfo.list.map((item, index) => (
                  <ProjectList
                    key={index}
                    item={item}
                    editProject={() =>
                      this.getProjectDetailsApi(this.props, item.slug)
                    }
                    projectsInfo={projectsInfo}
                    toggleModalFn={()=>this.setState({deleteProjectModal:true,deleteItem:item})}
                    onChangeProjectStatus={(data)=> this.setState({projectStatus:{modal:true, data:data, slug:item.slug}})}
                  />
                ))}
                noRecord={
                  projectsInfo.list.length ? null : (
                    <EmptyCollection title="No records found" />
                  )
                }
                pagination={projectsInfo.pagination}
                onChangePage={(event, newPage) =>
                  this.getProjectsApi(
                    this.props,
                    {
                      ...filter,
                      project_status: tabIndex,
                      page: newPage + 1,
                      per_page: projectsInfo.pagination.per_page,
                    },
                    "pageLoader"
                  )
                }
                onChangeRowsPerPage={(event) =>
                  this.getProjectsApi(
                    this.props,
                    {
                      ...filter,
                      project_status: tabIndex,
                      page: 1,
                      per_page: event.target.value,
                    },
                    "pageLoader"
                  )
                }
                createSortHandler={this.createSortHandler}
              />
            </Fragment>
          )}
        </div>
        { projectStatus.modal ?
          <ProjectStatusPopUp
            toggleModalFn={() => this.setState({projectStatus:{modal:false, data:null}})}
            getResponseBack={() => { this.setState({projectStatus:{modal:false, data:null, slug:''}}); this.getProjectsApi(this.props, {...filter, sort: sort, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');}}
            projectStatus={projectStatus}
          />:null
        }
        {projectModal ?
          <ProjectCRU
            toggleModalFn={() =>
              this.setState({ projectModal: false, projectCrud: null })
            }
            modal={projectModal}
            projectCrud={projectCrud}
            lessee={lessee}
            getResponseBack={this.getResponseBack}
          />:null
        }
         {
          deleteProjectModal ?
           <DeletePopUp
           modal={deleteProjectModal}
           toggleModalFn={()=>this.setState({deleteProjectModal:false})}
           title="Delete Project"
           content={<h4>Are you sure you want to delete?</h4>}
           deleteRecordFn={()=>this.deleteProjectList(deleteItem)}

          />: null}

        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = (state) => ({
  lessee: state.sharedReducers.sLesseeList,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(sLesseeListAc()),
  }
}
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ProjectListing)
)
