import React, { useEffect, useState } from "react";
import { checkApiStatus, getLocalStorageInfo } from "../../../utils_v2";
import { globalGetService } from "../../../utils_v2/globalApiServices";
import AssetWidget from "../components/AssetWidget";
import '../../assets/styles/dashboard.scss'
import { Grid, Typography, TextField } from "@material-ui/core";
import { imgStoragePath } from "../../../constants";
import TechnicalLocation from "../components/TechnicalLocation";
import { Autocomplete } from "@material-ui/lab";
import { projectStatus } from "../../../constants";
import { PageLoader } from "../../../shared_elements";

const DashBoard = () => {
    const [projectsWidget, setProjectsWidget] = useState({});
    const [projectLocation, setProjectLocation] = useState();
    const [inspectionType, setInspectionType] = useState();
    const [isLoading, setLoading] = useState();

    const [locQuery, setLocQuery] = useState({
        inspection_type: null,
        project_status: null
    });

    useEffect(() => {
        technicalProjectsWidgetAc();
        technicalProjectLocationtAc();
        technicalInspectionTypeAc();
    }, []);

    const technicalProjectsWidgetAc = () => {
        setLoading(true)
        globalGetService('technical/project-dashboard/', {})
            .then(response => {
                if (checkApiStatus(response)) {
                    setProjectsWidget(response.data.data);
                    setLoading(false)
                }
            });
    }

    const technicalProjectLocationtAc = (queryParams = {}) => {
        // setLoading(true)
        globalGetService('technical/project-dashboard/project-location/', queryParams)
            .then(response => {
                if (checkApiStatus(response)) {
                    setProjectLocation(response.data.data);
                    // setLoading(false)
                }
            });
    }

    const technicalInspectionTypeAc = () => {
        // setLoading(true)
        globalGetService('technical/inspection-types/', {})
            .then(response => {
                if (checkApiStatus(response)) {
                    setInspectionType(response.data.data);
                    // setLoading(false)
                }
            });
    }

    const projectWidget = [
        { type: 'all', url: '/technical/projects?project_status=all', title: 'TOTAL PROJECTS', classes: 'widget-cards  marginRight  flex-centered total-count' },
        { type: 'active', url: '/technical/projects?project_status=ongoing', title: 'ACTIVE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-lease' },
        { type: 'upcoming', url: '/technical/projects?project_status=upcoming', title: 'UPCOMING PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered off-lease' },
        { type: 'overdue', url: '/technical/projects?project_status=overdue', title: 'OVERDUE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-ground' },
        { type: 'completed', url: '/technical/projects?project_status=completed', title: 'COMPLETED PROJECTS', classes: 'widget-cards marginLeft flex-centered written-off' }
    ];

    const updateLocForm = (type, value) => {
        let data = { ...locQuery };
        let newData = {...data,[type]: value}
        setLocQuery(newData);
        technicalProjectLocationtAc(newData);
    }

    return (
        <div className="fleet-technical-db">
            <Grid container className="assets-row" style={{ marginLeft: '5px', marginRight: '6px' }}>
                {projectWidget.map((project, index) => (
                    <AssetWidget
                        className={project.classes}
                        statusGrpTitle={project.title}
                        value={projectsWidget[project.type]}
                        url={project.url}
                        dbAssetLoader={isLoading}
                    />
                ))}
            </Grid>
            <Grid container className="technical-manpower-info flex-centered" spacing={2} style={{padding:'2px'}}>
                {getLocalStorageInfo().user.permission.project_management && !getLocalStorageInfo().user.permission.project_management.is_saas && getLocalStorageInfo().user.type.value === 1 &&
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            {[
                                { title: 'Total MANPOWER', value: projectsWidget.total_manpower },
                                { title: 'Engaged MANPOWER', value: projectsWidget.engaged_manpower }
                            ].map((stats, index) => (
                                <Grid item md={6} key={index}>
                                    <div className="manpower-widget " style={{ alignContent: 'center' }}>
                                        {isLoading?<Typography variant="h6" style={{ color: '#ffffff' }}>Loading...</Typography>:(
                                        <>
                                        <Typography variant="h5">{stats.value}</Typography>
                                        <Typography variant="h6">{stats.title}</Typography>
                                        </>)}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                }
                <Grid item md={6} className="work-order">
                    <Grid container>
                        <Grid item md >
                        {isLoading?<Typography variant="h6" >Loading...</Typography>:
                        <>
                            <Typography variant="h5">{projectsWidget.total_assets}</Typography>
                            <Typography variant="h6">Total Work Orders</Typography>
                        </>}
                        </Grid>
                        <Grid item md style={{ alignContent: 'center' }}>
                            <img src={`${imgStoragePath}aircraft_circle.png`} alt="Work Orders" />
                        </Grid>
                        <Grid item md>
                        {isLoading?<Typography variant="h6" >Loading...</Typography>:
                        <>
                            <Typography variant="h5">{projectsWidget.active_assets}</Typography>
                            <Typography variant="h6">Total Active Work Orders</Typography>
                        </>}   
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="dashboard-override" style={{ padding: '3px', marginLeft: '8px' }}>
                <Grid container spacing={2} style={{ marginBottom: '-2px' }} className="projects-location">
                    <Grid item md={5}>
                        <Typography variant="h4" style={{alignContent:'center'}}>Location of Projects</Typography>
                    </Grid>
                    {inspectionType && (
                        <Grid item md={5}>
                            <Autocomplete
                                options={inspectionType}
                                getOptionLabel={(option) => option.name}
                                value={inspectionType.name}
                                onChange={(e, newValue) => {updateLocForm('inspection_type', newValue ? newValue.id : null)}}
                                renderInput={(params) => (<TextField {...params} label="Inspection Type" placeholder="Select Inspection Type" variant="outlined" fullWidth InputLabelProps={{ shrink: true }}/>)}
                            />
                        </Grid>
                    )}
                    <Grid item md={2}>
                        <Autocomplete
                            options={projectStatus}
                            getOptionLabel={(option) => option.label}
                            value={projectStatus.name}
                            onChange={(e, newValue) => {updateLocForm('project_status', newValue ? newValue.value : null)}}
                            renderInput={(params) => (<TextField {...params} fullWidth label="Project Status" placeholder="Select Project Status" variant="outlined" InputLabelProps={{ shrink: true }} />)}
                        />
                    </Grid>
                </Grid>
            </div>
            <Grid container className="projects-location" style={{marginLeft:'4px'}}>
                <Grid item md={12}>
                    <TechnicalLocation markerList={projectLocation} />
                </Grid>
            </Grid>
            { isLoading ? <PageLoader/> : null }
        </div>
        );
}

export default (DashBoard);
